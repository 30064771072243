//  DOCUMENT
html,
body,
#root {

    @include breakpoint() {

        min-height: 90vh;
        max-width: 100vw;

        overflow-x: hidden;

    }

}

body,
body > * {

    @include breakpoint() {

        font-family: $font;

    }

}

//  DESKTOP ERROR
body {

    @include breakpoint($desktop_small) {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    #root {

        @include breakpoint($desktop_small) {

            width: 420px;
            min-height: 90vh;

            margin: 0 auto;

        }

        header {

        }

        .desktop_error {

            @include breakpoint($desktop_small) {
                padding-top: $gap * 2;
            }

            .description {

                @include breakpoint($desktop_small) {

                    font-size: 18px;
                    line-height: 24px;

                    text-align: center;
                    margin-bottom: $gap;
                    padding-top: $gap;

                    width: 100%;

                }

            }

        }

    }

    &.mobile-only {

        @include breakpoint($desktop_small) {

            display: flex;
            flex-direction: column;
            justify-content: center;

        }

        #root {

            @include breakpoint($desktop_small) {

                width: 320px;
                height: 480px;

                flex-grow: 0;
                flex-shrink: 0;

                min-height: 0;

                border: solid 1px $gray;

            }

            .wrapper {

                @include breakpoint($desktop_small) {
                    width: 100%;
                    max-width: none;
                    box-sizing: border-box;
                }
                
            }

        }

    }

}