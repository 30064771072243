//  BREAKPOINTS
$mobile_large: 25em;//  414
$tablet_small: 48em;//  768
$tablet_large: 64em;//  1024
$desktop_small: 80em;//  1280
$desktop_medium: 90em;//  1440
$desktop_large: 120em;//  1920

//  COLORS
$white: #ffffff;
$gray: #eeeeee;
$black: #000000;
$blue: #4696F2;

// FONT
$font: 'Raleway', sans-serif;

//  FONT WEIGHTS
$regular: 400;
$semi_bold: 600;
$bold: 700;

//  GAP
$gap : 20px;