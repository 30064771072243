.results {

    .results_id_sig {

        @include breakpoint() {

            justify-content: space-between;
            margin: 0 0 $gap 0;

        }

        .results_id {

            @include breakpoint() {

                flex: none;
                width: calc(30% - 10px);
                height: 128px;

                padding: 0 10px 0 0;

            }

            img {

                @include breakpoint() {

                    display: block;
                    height: 100%;
                    width: auto;

                    margin: 0;

                }

            }

        }
    
        .results_sig {
    
            @include breakpoint() {

                flex: none;
                width: calc(70% - 10px);
                max-width: 240px;

                padding: 0 0 0 10px;

                display: flex;
                flex-direction: column;
                justify-content: center;

            }

            img {

                @include breakpoint() {

                    display: block;
                    width: 100%;
                    height: auto;

                }

            }

        }

        .results_medcard {

            @include breakpoint() {

                width: calc(50% - 10px);
                height: auto;

            }

            img {

                @include breakpoint() {

                    display: block;
                    width: 100%;
                    height: auto;

                }

            }

        }

    }

    .results_data {

        @include breakpoint() {

            margin: 0 0 $gap 0;

        }

        .row {

            @include breakpoint() {

                padding: 0 0 10px 0;
                margin: 0 0 10px 0;

                border-bottom: solid 1px $gray;

            }

            &:last-of-type {

                @include breakpoint() {

                    border-bottom: none;
                    margin: 0;

                }

            }

            .type,
            .data {

                @include breakpoint() {

                    flex-grow: 1;
                    flex-shrink: 1;
                    flex-basis: 0;

                }

            }

            .type {

                @include breakpoint() {

                    font-size: 14px;
                    line-height: 24px;
                    font-weight: $bold;

                }

            }

            .data {

                @include breakpoint() {

                    white-space: pre-line;
                    font-size: 14px;
                    line-height: 24px;

                }

            }

        }

    }

}