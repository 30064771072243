.row {

    @include breakpoint() {

        display: flex;
        flex-direction: row;

    }

}

.column {

    @include breakpoint() {

        display: flex;
        flex-direction: column;

    }

}