.btn {
    &.ifg {
        background-color: #ffffff;
        color: #f16a22;
        border: 1px solid #415a8b;
        border-radius: 17px;
        cursor: pointer;

        p {
            padding: 5px 10px;
            line-height: 22px;
        }
    }
    @include breakpoint() {

        color: $white;
        background-color: $blue;

        font-size: 16px;
        line-height: 48px;
        font-weight: $bold;

        text-align: center;

        margin: 0 0 $gap 0;

        display: block;

    }

    &.outline {
        &.ifg {
            background-color: #edf7f6;
            color: #1e5497;
            border: 1px solid #4c89c2;
            box-shadow: none;
        }
        @include breakpoint() {

            color: $blue;
            background-color: $white;

            @include box-shadow(inset 0px 0px 0px 2px $blue);

        }

    }

}
