html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@-webkit-keyframes search {
  0% {
    top: 40px;
    left: 60px;
  }
  25% {
    top: 40px;
    left: calc(100% - 120px);
  }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px);
  }
  75% {
    top: calc(100% - 100px);
    left: 60px;
  }
  100% {
    top: 40px;
    left: 60px;
  }
}
@-moz-keyframes search {
  0% {
    top: 40px;
    left: 60px;
  }
  25% {
    top: 40px;
    left: calc(100% - 120px);
  }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px);
  }
  75% {
    top: calc(100% - 100px);
    left: 60px;
  }
  100% {
    top: 40px;
    left: 60px;
  }
}
@-o-keyframes search {
  0% {
    top: 40px;
    left: 60px;
  }
  25% {
    top: 40px;
    left: calc(100% - 120px);
  }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px);
  }
  75% {
    top: calc(100% - 100px);
    left: 60px;
  }
  100% {
    top: 40px;
    left: 60px;
  }
}
@keyframes search {
  0% {
    top: 40px;
    left: 60px;
  }
  25% {
    top: 40px;
    left: calc(100% - 120px);
  }
  50% {
    top: calc(100% - 100px);
    left: calc(100% - 120px);
  }
  75% {
    top: calc(100% - 100px);
    left: 60px;
  }
  100% {
    top: 40px;
    left: 60px;
  }
}
.spinner {
  color: #f16a22;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner div {
  transform-origin: 40px 40px;
  animation: spinner 1.2s linear infinite;
}
.spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #f16a22;
}
.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@media screen {
  .hidden {
    display: none;
  }
}

@media screen {
  html,
  body,
  #root {
    min-height: 90vh;
    max-width: 100vw;
    overflow-x: hidden;
  }
}

@media screen {
  body,
  body > * {
    font-family: "Raleway", sans-serif;
  }
}

@media screen and (min-width: 80em) {
  body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (min-width: 80em) {
  body #root {
    width: 420px;
    min-height: 90vh;
    margin: 0 auto;
  }
}
@media screen and (min-width: 80em) {
  body #root .desktop_error {
    padding-top: 40px;
  }
}
@media screen and (min-width: 80em) {
  body #root .desktop_error .description {
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
    padding-top: 20px;
    width: 100%;
  }
}
@media screen and (min-width: 80em) {
  body.mobile-only {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 80em) {
  body.mobile-only #root {
    width: 320px;
    height: 480px;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 0;
    border: solid 1px #eeeeee;
  }
}
@media screen and (min-width: 80em) {
  body.mobile-only #root .wrapper {
    width: 100%;
    max-width: none;
    box-sizing: border-box;
  }
}

@media screen {
  .wrapper {
    padding: 0 20px;
    max-width: calc(100vw - ( 2 * 20px ));
  }
}

@media screen {
  .row {
    display: flex;
    flex-direction: row;
  }
}

@media screen {
  .column {
    display: flex;
    flex-direction: column;
  }
}

.btn.ifg {
  background-color: #ffffff;
  color: #f16a22;
  border: 1px solid #415a8b;
  border-radius: 17px;
  cursor: pointer;
}
.btn.ifg p {
  padding: 5px 10px;
  line-height: 22px;
}
@media screen {
  .btn {
    color: #ffffff;
    background-color: #4696F2;
    font-size: 16px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    margin: 0 0 20px 0;
    display: block;
  }
}
.btn.outline.ifg {
  background-color: #edf7f6;
  color: #1e5497;
  border: 1px solid #4c89c2;
  box-shadow: none;
}
@media screen {
  .btn.outline {
    color: #4696F2;
    background-color: #ffffff;
    -webkit-box-shadow: inset 0px 0px 0px 2px #4696F2;
    -moz-box-shadow: inset 0px 0px 0px 2px #4696F2;
    -ms-box-shadow: inset 0px 0px 0px 2px #4696F2;
    box-shadow: inset 0px 0px 0px 2px #4696F2;
  }
}

@media screen {
  header {
    padding: 20px 0;
  }
}

@media screen {
  .logo {
    width: 50px;
    height: 48px;
    background-image: url("../assets/images/ifg-id-scan-logo@2x@3x.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
  }
}

.languageDropDown {
  margin-left: auto;
  position: relative;
  padding-right: 15px;
  text-align: center;
  z-index: 1301;
}
.languageDropDown.open visibleBlock svg {
  fill: #ff8b1e;
}
.languageDropDown.open visibleBlock p, .languageDropDown.open visibleBlock span {
  color: #ff8b1e;
}
.languageDropDown.open visibleBlock span {
  transform: rotate(-180deg);
}
.languageDropDown.open .choiceOfLanguage {
  max-height: 200px;
  opacity: 1;
}

.visibleBlock {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.visibleBlock .globeIcon {
  fill: #f16a22;
  height: 22px;
}
.visibleBlock p {
  font-size: 12px;
  margin-top: 7px;
}
.visibleBlock span {
  position: absolute;
  font-size: 10px;
  right: -15px;
  line-height: 0;
  top: 20%;
  transform-origin: 50% 45%;
  transform: rotate(0deg);
  transition: transform 0.25s ease-in;
}
.visibleBlock span::before {
  content: "";
  border: 4px solid transparent;
  border-top: 7px solid;
  display: inline-block;
  border-bottom: 0px;
}

.choiceOfLanguage {
  position: absolute;
  max-height: 0;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: auto;
  right: -10px;
  top: 47px;
  width: 225px;
  background: white;
  box-shadow: 3px 4px 12px -1px #d8d8d8;
  transition: max-height 0.4s ease-in, opacity 0.2s ease-in;
  border: 1px solid #f16a22;
  border-radius: 7px;
}
.choiceOfLanguage p {
  font-size: 13px;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  cursor: pointer;
}
.choiceOfLanguage p.selected {
  background: #f2f2f2;
}

@media screen {
  .description_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 0 20px 0;
  }
}
@media screen {
  .description_container .description {
    font-family: "Raleway", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.5;
    display: block;
  }
}

@media screen {
  .instructions ul {
    padding: 0 0 0 20px;
    margin: 0 0 20px;
  }
}
@media screen {
  .instructions ul li {
    margin: 0 0 10px 0;
    padding: 0 0 10px 0;
    border-bottom: solid 1px #eeeeee;
    list-style: square;
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen {
  .instructions ul li:last-child {
    margin: 0;
    border-bottom: none;
  }
}

@media screen and (orientation: landscape) and (max-width: 48em) {
  .example_list {
    display: flex;
    flex-direction: row;
  }
}
@media screen {
  .example_list .example {
    display: flex;
    flex-direction: row;
    margin: 0 0 20px 0;
  }
}
@media screen and (orientation: landscape) and (max-width: 48em) {
  .example_list .example {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
}
@media screen {
  .example_list .example .example_text {
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media screen {
  .icon {
    flex: none;
    width: 32px;
    height: 32px;
    margin: 0 20px 0 0;
  }
}

@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group .capture_container {
    width: 60%;
    box-sizing: border-box;
  }
}
@media screen {
  .capture_photo .capture_group .capture_container .capture {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0 0 20px 0;
  }
}
@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group .capture_container .capture {
    margin: 0;
  }
}
@media screen and (orientation: landscape) and (max-width: 48em) {
  .capture_photo .capture_group .capture_controls {
    width: 40%;
    box-sizing: border-box;
    justify-content: center;
  }
}
@media screen and (orientation: landscape) and (max-width: 48em) {
  .analyzing .analyzing_group .analyzing_container {
    width: 60%;
    box-sizing: border-box;
  }
}
@media screen {
  .analyzing .analyzing_group .analyzing_container .analyzing_animation_zone {
    position: relative;
  }
}
@media screen {
  .analyzing .analyzing_group .analyzing_container .analyzing_animation_zone .id_background {
    display: block;
    width: 100%;
    height: auto;
  }
}
@media screen {
  .analyzing .analyzing_group .analyzing_container .analyzing_animation_zone::before {
    content: "";
    display: block;
    width: 64px;
    height: 64px;
    background-image: url("../assets/images/finder@2x.png");
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: center center;
    background-size: contain;
    position: absolute;
    -webkit-animation: search 4s ease-in-out infinite forwards 0s;
    -moz-animation: search 4s ease-in-out infinite forwards 0s;
    -o-animation: search 4s ease-in-out infinite forwards 0s;
    animation: search 4s ease-in-out infinite forwards 0s;
  }
}

@media screen {
  .results .results_id_sig {
    justify-content: space-between;
    margin: 0 0 20px 0;
  }
}
@media screen {
  .results .results_id_sig .results_id {
    flex: none;
    width: calc(30% - 10px);
    height: 128px;
    padding: 0 10px 0 0;
  }
}
@media screen {
  .results .results_id_sig .results_id img {
    display: block;
    height: 100%;
    width: auto;
    margin: 0;
  }
}
@media screen {
  .results .results_id_sig .results_sig {
    flex: none;
    width: calc(70% - 10px);
    max-width: 240px;
    padding: 0 0 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen {
  .results .results_id_sig .results_sig img {
    display: block;
    width: 100%;
    height: auto;
  }
}
@media screen {
  .results .results_id_sig .results_medcard {
    width: calc(50% - 10px);
    height: auto;
  }
}
@media screen {
  .results .results_id_sig .results_medcard img {
    display: block;
    width: 100%;
    height: auto;
  }
}
@media screen {
  .results .results_data {
    margin: 0 0 20px 0;
  }
}
@media screen {
  .results .results_data .row {
    padding: 0 0 10px 0;
    margin: 0 0 10px 0;
    border-bottom: solid 1px #eeeeee;
  }
}
@media screen {
  .results .results_data .row:last-of-type {
    border-bottom: none;
    margin: 0;
  }
}
@media screen {
  .results .results_data .row .type,
  .results .results_data .row .data {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  }
}
@media screen {
  .results .results_data .row .type {
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen {
  .results .results_data .row .data {
    white-space: pre-line;
    font-size: 14px;
    line-height: 24px;
  }
}

