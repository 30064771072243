//  HEADER

header {

    @include breakpoint() {

        padding: $gap 0;

    }

}

//  LOGO
.logo {

    @include breakpoint() {

        width: 50px;
        height: 48px;

        background-image: url('../assets/images/ifg-id-scan-logo@2x@3x.png');
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center center;
        background-size: contain;

    }

}

.languageDropDown {
    margin-left: auto;
    position: relative;
    padding-right: 15px;
    text-align: center;
    z-index: 1301;

    &.open {
        visibleBlock {
            svg {
                fill: #ff8b1e;
            }

            p, span {
                color: #ff8b1e;
            }

            span {
                transform: rotate(-180deg);
            }
        }

        .choiceOfLanguage {
            max-height: 200px;
            opacity: 1;
        }
    }
}

.visibleBlock {
    position: relative;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;

    .globeIcon {
        fill: #f16a22;
        height: 22px;
    }

    p {
        font-size: 12px;
        margin-top: 7px;
    }

    span {
        position: absolute;
        font-size: 10px;
        right: -15px;
        line-height: 0;
        top: 20%;
        transform-origin: 50% 45%;
        transform: rotate(0deg);
        transition: transform .25s ease-in;

        &::before {
            content: "";
            border: 4px solid transparent;
            border-top: 7px solid;
            display: inline-block;
            border-bottom: 0px;
        }
    }
}

.choiceOfLanguage {
    position: absolute;
    max-height: 0;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: auto;
    right: -10px;
    top: 47px;
    width: 225px;
    background: white;
    box-shadow: 3px 4px 12px -1px #d8d8d8;;
    transition: max-height 0.4s ease-in, opacity 0.2s ease-in;
    border: 1px solid #f16a22;
    border-radius: 7px;

    p {
        font-size: 13px;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
        text-align: left;
        cursor: pointer;

        &.selected {
            background: #f2f2f2;
        }
    }
}
