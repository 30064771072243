
.description_container {

    @include breakpoint() {

        display: flex;
        flex-direction: row;
        align-items: center;

        flex-wrap: wrap;

        width: 100%;

        margin: 0 0 $gap 0;

    }


    .description {

        @include breakpoint() {
    
            font-family: $font;
            font-weight: $bold;
            font-size: 16px;
            line-height: 1.5;
    
            display: block;

        }

    }

}
